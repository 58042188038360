import busSVG from '@fortawesome/fontawesome-free/svgs/solid/bus.svg';

function addColor(url, color) {
    const encodedColor = color.replace('#', '%23');
    return url.replace('%3e', ` fill=\'${encodedColor}\' stroke=\'${encodedColor}\'%3e`);
}

class GtfsPlugin {

    constructor(options) {
        const me = this;

        me.id = 'gtfs';
        me.name = {
            de: 'GTFS',
            en: 'GTFS',
            es: 'GTFS',
            fr: 'GTFS',
            ja: 'GTFS',
            ko: 'GTFS',
            ne: 'GTFS',
            pt: 'GTFS',
            th: 'GTFS',
            'zh-Hans': 'GTFS',
            'zh-Hant': 'GTFS'
        };
        me.iconStyle = {
            backgroundSize: '32px',
            backgroundImage: `url("${addColor(busSVG, 'white')}")`
        };
        me.enabled = options && options.enabled;
    }

    onAdd(map) {
        this.map = map;
    }

    onEnabled() {
        this.map.setDataSources([{
            gtfsUrl: 'https://api-public.odpt.org/api/v4/files/Toei/data/ToeiBus-GTFS.zip',
            vehiclePositionUrl: 'https://api-public.odpt.org/api/v4/gtfs/realtime/ToeiBus',
            color: '#9FC105'
        }, {
            gtfsUrl: 'https://api.odpt.org/api/v4/files/YokohamaMunicipal/data/YokohamaMunicipal-Bus-GTFS.zip',
            vehiclePositionUrl: 'https://api.odpt.org/api/v4/gtfs/realtime/YokohamaMunicipalBus_vehicle',
            color: '#1B1464'
        }, {
            gtfsUrl: 'https://api-public.odpt.org/api/v4/files/odpt/KeiseiTransitBus/AllLines.zip?date=current',
            vehiclePositionUrl: 'https://api-public.odpt.org/api/v4/gtfs/realtime/odpt_KeiseiTransitBus_AllLines_vehicle',
            color: '#C73734'
        }]);
    }

    onDisabled() {
        this.map.setDataSources([]);
    }

}

export default function(options) {
    return new GtfsPlugin(options);
}
